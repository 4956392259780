module.exports = [{
      plugin: require('/Users/gfu/repos/scope-travel-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/gfu/repos/scope-travel-gatsby/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[],"autoLabel":true},
    },{
      plugin: require('/Users/gfu/repos/scope-travel-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-77658152-8"},
    },{
      plugin: require('/Users/gfu/repos/scope-travel-gatsby/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":85},
    },{
      plugin: require('/Users/gfu/repos/scope-travel-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":false,"showCaptions":false,"withWebp":true},
    }]
