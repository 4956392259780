// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/gfu/repos/scope-travel-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-destinations-tsx": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/destinations.tsx" /* webpackChunkName: "component---src-pages-destinations-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("/Users/gfu/repos/scope-travel-gatsby/src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-pages-404-js": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-tsx": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-system-tsx": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/design-system.tsx" /* webpackChunkName: "component---src-pages-design-system-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thank-you-js": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-ui-header-tsx": () => import("/Users/gfu/repos/scope-travel-gatsby/src/pages/UI/Header.tsx" /* webpackChunkName: "component---src-pages-ui-header-tsx" */)
}

